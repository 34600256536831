import React, { ReactElement, useState } from 'react';
import styles from '../footer.module.scss';
import expandIcon from '../assets/expand.svg';
import collapseIcon from '../assets/collapse.svg';
import { ExternalLink } from '@mo/external-link';

interface Link {
  name: string;
  href: string;
  dataGa?: string;
}

interface Action {
  name: string;
  action: () => void;
  dataGa?: string;
}

interface LinkGroupProps {
  heading: string;
  links: (Link | Action)[];
}

const LinkGroup: React.FunctionComponent<LinkGroupProps> = (props): ReactElement => {
  const [expand, setExpand] = useState(false);

  const isLink = (link: Link | Action): link is Link => {
    return (link as Link).href !== undefined;
  };

  const isAction = (action: Link | Action): action is Action => {
    return (action as Action).action !== undefined;
  };

  const accordionId = props.heading.split(' ')[0];

  return (
    <li className={styles.linkGroup}>
      <h2 className={styles.nonLinkHeader}>{props.heading}</h2>
      <button
        className={styles.linkHeader}
        onClick={(): void => setExpand(!expand)}
        tabIndex={0}
        aria-expanded={expand}
        id={accordionId}
        aria-controls={accordionId}
      >
        <h2>{props.heading}</h2>
        {expand ? (
          <img src={collapseIcon} alt={'Collapse'} aria-hidden='true' />
        ) : (
          <img src={expandIcon} alt={'Expand'} aria-hidden='true' />
        )}
      </button>
      <div id={accordionId + '-body'} aria-hidden='false' aria-labelledby={accordionId} role='region'>
        <ul className={expand ? styles.expanded : styles.collapsed}>
          {props.links.map(
            (item, index): ReactElement => (
              <li key={index}>
                {isLink(item) && (
                  <ExternalLink href={item.href} analyticsTag={item.dataGa ? item.dataGa : ''}>
                    {item.name}
                  </ExternalLink>
                )}
                {isAction(item) && (
                  <button onClick={item.action} data-ga={item.dataGa ? item.dataGa : ''}>
                    {item.name}
                  </button>
                )}
              </li>
            ),
          )}
        </ul>
      </div>
    </li>
  );
};

export default LinkGroup;
