import React, { ReactElement } from 'react';
import './common/gearboxComponents';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import { Routes } from './Routes';

const AppContainer: React.FunctionComponent = (): ReactElement | null => {
  return (
    <>
      <Header />
      <main>
        <ScrollToTop />
        <Routes />
      </main>
      <Footer />
    </>
  );
};

export default AppContainer;
