import React, { ReactElement } from 'react';
import styles from './footer.module.scss';
import LinkGroup from './linkGroup/LinkGroup';
import facebook from './assets/facebook.svg';
import twitter from './assets/twitter.svg';
import youtube from './assets/youtube.svg';
import linkedIn from './assets/linkedin.svg';
import { ExternalLink } from '@mo/external-link';
import cn from 'classnames';

interface FooterProps {
  className?: string;
}

const Footer: React.FunctionComponent<FooterProps> = ({ className }): ReactElement => {
  return (
    <footer className={cn(className, styles.footer)}>
      <section className={styles.links}>
        <div className={styles.linksContainer}>
          <ul>
            <LinkGroup
              heading={'General'}
              links={[
                { name: 'Contact us', href: 'https://www.motability.co.uk/get-support/contact/', dataGa: 'contact-us' },
              ]}
            />
            <LinkGroup
              heading={'About us'}
              links={[
                {
                  name: 'Who we are',
                  href: 'https://www.motability.co.uk/how-it-works/who-we-are/?utm_source=scheme-website&utm_medium=link&utm_campaign=check-account-ready',
                  dataGa: 'who-we-are',
                },
              ]}
            />
            <LinkGroup
              heading={'Terms'}
              links={[
                {
                  name: 'Website Terms and Conditions',
                  href: 'https://www.motability.co.uk/utilities/terms-and-conditions',
                  dataGa: 'website-terms-and-conditions',
                },
                {
                  name: 'Web messenger terms of use',
                  href: 'https://www.motability.co.uk/utilities/genesys-web-messenger/',
                  dataGa: 'web-chat-terms-of-use',
                },
                {
                  name: 'Accessibility',
                  href: 'https://www.motability.co.uk/utilities/accessibility/',
                  dataGa: 'accessibility',
                },
                {
                  name: 'Privacy notice',
                  href: 'https://www.motability.co.uk/utilities/privacy-notice/',
                  dataGa: 'privacy-notice',
                },
                {
                  name: 'Change cookie settings',
                  action: (): void => {
                    if (window.hasOwnProperty('cookiefirst_show_settings')) {
                      // @ts-ignore
                      window.cookiefirst_show_settings();
                    }
                  },
                  dataGa: 'change-cookie-settings',
                },
              ]}
            />
          </ul>
        </div>
      </section>
      <section className={styles.social}>
        <div className={styles.socialContainer}>
          <p>
            The Scheme is operated by Motability Operations Ltd, under contract to Motability, a registered Charity.
            Motability Operations Limited is authorised and regulated by the Financial Conduct Authority under reference
            number 735390. Please see our{' '}
            <ExternalLink
              href='https://www.motability.co.uk/utilities/terms-and-conditions'
              analyticsTag='website-terms-and-conditions'
            >
              Terms and Conditions
            </ExternalLink>{' '}
            for more information.
          </p>
          <ul>
            <li>
              <ExternalLink href={'https://www.facebook.com/motability'} analyticsTag='footer-facebook'>
                <img src={facebook} alt={'Facebook'} />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href={'https://twitter.com/motabilitypress'} analyticsTag='footer-twitter'>
                <img src={twitter} alt={'Twitter'} />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href={'https://www.youtube.com/user/MotabilityUK'} analyticsTag='footer-youtube'>
                <img src={youtube} alt={'Youtube'} />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                href={'https://www.linkedin.com/company/motability-operations/'}
                analyticsTag='footer-linkedin'
              >
                <img src={linkedIn} alt={'LinkedIn'} />
              </ExternalLink>
            </li>
          </ul>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
