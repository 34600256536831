import React, { ReactElement } from 'react';
import { ReactComponent as MoLogo } from './assets/mo-logo-white.svg';
import styles from './header.module.scss';

const Header: React.FunctionComponent = (): ReactElement => {
  return (
    <div data-locator={'header'} className={styles.header}>
      <nav className={styles.appHeader}>
        <div className={styles.logoContainer}>
          <MoLogo />
        </div>
      </nav>
    </div>
  );
};

export default Header;
